<template>
  <div class="bg-ligtGary">
    <TopNav/>
    <div class="header header-cart">
      <div class="w w1200">
        <div class="logo">
          <div class="logoImg">
            <router-link :to="{path:'/'}">
              <img :src="require('@/assets/co/' + co + '/logo.png')">
            </router-link>
          </div>
        </div>
        <div class="dsc-search">
          <div class="form">
            <form id="searchForm" name="searchForm" method="get" action="search.php" onsubmit="return checkSearchForm(this)" class="search-form">
              <input autocomplete="off" onkeyup="lookup(this.value);" name="keywords" type="text" id="keyword" value="手机" class="search-text">
              <input type="hidden" name="store_search_cmt" value="0">
              <button type="submit" class="button button-icon"><i></i></button>
            </form>

            <div class="suggestions_box" id="suggestions" style="display:none;">
              <div class="suggestions_list" id="auto_suggestions_list">
                &nbsp;
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="w w1200">
        <div class="payOrder-warp" id="pay_main">
          <div class="payOrder-desc">
            <div class="pay-top">
              <h3>
                订单提交成功！
              </h3>
              <div class="pay-total">
                <span>应付总额：</span>
                <div class="pay-price">¥{{goodsAmount}}</div>
              </div>
            </div>
            <div class="pay-txt">
              <p>订单号：<em id="nku">{{ id }}</em></p>
            </div>
            <router-link :to="{path:'/orderList'}" target="_blank" class="orderPrint ftx-05">
              我的订单
            </router-link>&nbsp;&nbsp;
            <router-link :to="{path:'/'}" target="_blank" class="orderPrint ftx-05">
              继续购物
            </router-link>
          </div>

          <!--
          <div class="payOrder-mode">
            <div class="payOrder-list">
              <div class="p-mode-tit">
                <h3>在线支付</h3>
              </div>
              <div class="p-mode-list">
                <div :class="['p-mode-item wxpay',{'item-selected':payType=='wxpay'}]" v-on:click="payType='wxpay'">
                  <input type="button" value="wxpay" class="btn btn-info ">
                </div>
                <div :class="['p-mode-item alipay',{'item-selected':payType=='alipay'}]" v-on:click="payType='alipay'">
                  <input type="button" value="alipay" class="btn btn-info ">
                </div>
              </div>
            </div>
            <div class="single-btn" id="pay_button" style="background: rgb(244, 36, 36);">
              <a href="javascript:;" class="weizf" v-on:click="toPay">立即支付</a>
            </div>
          </div>
          -->
        </div>
        <guess-love cssName="p-panel-main c-history" :count="6"></guess-love>
      </div>
    </div>

    <Footer/>

    <wxpay ref="wxpay" v-if="wxpayVisible"></wxpay>
  </div>
</template>

<script>
import Footer from '@/components/footer/Index'
import GuessLove from '@/components/GuessLove/Index'
import TopNav from '@/components/TopNav/Index'
import wxpay from "@/views/cart/pay/wxpay.vue";
import {getCartDetail} from '@/api/user'
export default {
  name: "CartSuccess",
  data() {
    return {
      goodsAmount:0,
      id:"",
      payType: 'wxpay', //wxpay,alipay
      wxpayVisible: false,
    }
  },
  components: {
    Footer,
    GuessLove,
    TopNav,
    wxpay
  },
  created() {
    this.getOrderDetail()
    this.id = this.$route.query.id
  },
  methods:{
    getOrderDetail(){
      // eslint-disable-next-line no-unused-vars
      let id = this.$route.query.id
      getCartDetail({
        id: this.$route.query.id
      }).then(res => {

        this.goodsAmount = res.data.goodsAmount
        // if(res.status){
        //   this.$router.push({path:'/cartSuccess',query:{id:res.data.orderId}});
        // }else{
        //   this.$message({
        //     message: res.msg,
        //     type: 'success'
        //   });
        // }
      })
    },
    toPay(){
      if(this.payType=='wxpay'){

        this.wxpayVisible = true
        this.$nextTick(() => {
          this.$refs.wxpay.init()
        })

      }
    }
  }
}
</script>

<style scoped>

</style>
